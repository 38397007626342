import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './BlogTemplate.css';
import LoadingSpinner from '../Loading/LoadingSpinner'; // Import the LoadingSpinner component

const BlogTemplate = () => {
  const [blogs, setBlogs] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch("https://dholera-backend.onrender.com/blogs"); // Replace with your API URL
        if (!response.ok) {
          throw new Error("Failed to fetch blogs");
        }
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs:", error.message);
      }
    };

    fetchBlogs();
  }, []);

  const handleBlogClick = (id) => {
    history.push(`/blogs/${id}`); // Navigate to the detailed blog page
  };

  if (blogs.length === 0) {
    return <LoadingSpinner />; // Show the spinner while loading
  }

  return (
    <div className="blog-container">
      <div className="blog-grid">
        {blogs.map((blog, index) => (
          <div
            className={`blog-card size-${index % 3}`}
            key={blog._id}
            onClick={() => handleBlogClick(blog._id)}
          >
            <div className="blog-content">
              <h2 className="blog-title">{blog.title}</h2>
              <p className="blog-intro">
                {blog.content[0]?.paragraph.slice(0, 100)}...
              </p>
              <button className="read-more-button">Read More</button>
            </div>
            <img
              src={blog.content[0]?.imageUrl || 'default-image.jpg'}
              alt={blog.title}
              className="blog-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogTemplate;
