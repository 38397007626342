import React from "react";
import './LoadingSpinner.css'; // The CSS file for animation

const LoadingSpinner = () => {
  return (
    <div className="loading-spinner">
      <div className="spinner-dot"></div>
      <div className="spinner-dot"></div>
      <div className="spinner-dot"></div>
    </div>
  );
};

export default LoadingSpinner;
