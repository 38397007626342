import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './BlogPost.css';
import LoadingSpinner from '../Loading/LoadingSpinner'; // Import the LoadingSpinner component

const BlogPost = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blog, setBlog] = useState(null); // State to store the specific blog
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`https://dholera-backend.onrender.com/blogs/${id}`); // Replace with your API URL
        if (!response.ok) {
          throw new Error("Blog not found");
        }
        const data = await response.json();
        setBlog(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blog:", error.message);
        setLoading(false);
      }
    };

    fetchBlog();
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [id]);

  if (loading) {
    return <LoadingSpinner />; // Show the spinner while loading
  }

  if (!blog) {
    return <h2>Blog not found</h2>;
  }

  return (
    <div className="blog-post-container">
      <h1 className="blog-post-title">{blog.title}</h1>
      <p className="blog-post-author">Author: {blog.author || "Unknown"}</p>
      <p className="blog-post-date">
        Published on:{" "}
        {new Date(blog.createdAt).toLocaleDateString("en-US", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </p>
      {blog.content.map((section, index) => (
        <div key={section._id || index} className="blog-post-section">
          {section.heading && <h2 className="section-heading">{section.heading}</h2>}
          <p className="section-paragraph">{section.paragraph}</p>
          {section.imageUrl && <img src={section.imageUrl} alt={`Section ${index + 1}`} />}
        </div>
      ))}
    </div>
  );
};

export default BlogPost;
