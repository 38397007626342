import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './style.css';
import Home from './views/home';
import NotFound from './views/not-found';

import Nav from './components/nav_and_footer/nav';

import About from './views/about';
// import "./i18n.js";


import Mumbai from './views/mumbai';
import PropertyPage from './components/PropertyPages/property';
import Contact_us from './views/contactus';
import Dholera from './views/dholera';
import Footer from './components/nav_and_footer/footer';
import BlogTemplate from './components/blog/blogtemplate';
import VideoPlayer from './components/videos/youtubeVideo';
import PropertyDetails from './components/PropertyDetails/propertyDetails';
import Privacy from './components/Privacy_Policy/Privacy';
import BlogPost from './components/blog/BlogPost';
import FormPopUp from './components/Pop-Up/popUpForm';

const App = () => {
  // const selectedCrop = 'rice';
 
  return (
    <div className="App">
      <Router>
        <Nav />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/blog" component={BlogTemplate} exact />
          <Route path="/blogs/:id" component={BlogPost} />
    
          <Route path='/aboutus' component={About} />
  
    
          <Route path='/mumbai' component={Mumbai} />
          <Route path='/dholera' component={Dholera} />
          <Route path = '/property' component = {PropertyPage} />
          <Route path = '/contactus' component = {Contact_us} />
          <Route path = '/propertydetails' component = {PropertyDetails} />
          <Route path = '/video' component = {VideoPlayer} />
          <Route path = '/policy' component = {Privacy} />
         
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Router>
      {/* <FormPopUp/> */}
    </div>
  );
};

// ReactDOM.render(<App />, document.getElementById('app'));

ReactDOM.render(<App />, document.getElementById('app'));